function Contact() {
  return (
    <div id="kontakt" className="container contact">
      <h2 className="main-title text-center">Kontakt</h2>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4 mb-1">
            <input name="name" placeholder="Name" className="contact-input" />
          </div>

          <div className="col-md-4 mb-1">
            <input name="email" placeholder="Email" className="contact-input" />
          </div>
          <div className="col-md-4 mb-1">
            <input
              name="betreff"
              placeholder="Betreff"
              className="contact-input"
            />
          </div>
        </div>
      </div>
      <br />
      <div className="col-md-12">
      <textarea
            name="nachricht"
            placeholder="Nachricht"
            className="contact-textarea"
          />
      </div>

      <br></br>
      <div className="row">
        <div className="col-md-12">
          <input className="form-btn" type="submit" value="Nachricht senden" />
        </div>
      </div>
    </div>
  );
}
export default Contact;
