import React from "react";
import Vid1 from "./../img/video2.mp4"

function Header() {
  return (
    <header>
      <video src={Vid1} loop autoPlay muted></video>
      <h1>Show Webseite</h1>
      <div className="row">
        <button className="btn" style={{ cursor: "pointer" }}>
          <a href="https://q-tech.dev/beispiele" target="_blank" rel="noreferrer">Q-Tech Dev</a>
        </button>

        <button className="btn" style={{ cursor: "pointer" }}>
          <a href="#kontakt">Kontakt</a>
        </button>
      </div>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
